<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      width="width:45rem"
      padding="padding:0 1.2rem"
      @submit="handleSubmit(submit)"
      :disabled-button="disableButton"
      submit="Save"
      :loading="loader"
      @close="closeModal"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple capitalize">Outstandings</p>
      </template>

      <div
        style="border-spacing: 15px 0; background: #f7f7ff !important"
        class="mt-4 bg-ghostWhite p-3"
      >
        <span class="font-bold text-lg p-3 text-darkPurple">
          Net Outstanding
        </span>
        <span class="font-bold">
          {{ convertToCurrency(totalAmount) }}
        </span>
      </div>

      <div>
        <table aria-label="table" class="w-full mt-4">
          <thead>
            <tr>
              <th
                class="uppercase"
                id="header"
                v-for="(header, index) in headers"
                :key="index"
              >
                <p>{{ header.title }}</p>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, i) in forms"
              :key="i"
              class="tableBody text-sm"
              style="height: 48px"
            >
              <td class="w-1/3">
                <c-text placeholder="--Enter--" v-model="data.name" class="input" :rules="['required']" label="Item" />
              </td>
              <td class="w-1/3">
                <c-text placeholder="--Enter--" v-model="data.amount" class="input" :rules="['required']" label="Amount" />
              </td>
              <td class="w-1/3">
                <c-select
                  :options="Options"
                  variant="w-full"
                  v-model="data.type"
                  @onChange="setNetOutstanding()"
                  :rules="['required']"
                  class="input"
                  label="Type"
                />
              </td>
              <td>
                <Icon
                  size="s"
                  class="text-flame cursor-pointer"
                  @click.native="removeForm(data, i)"
                  icon-name="close"
                />
              </td>
            </tr>
            <tr class="h-7" />
            <tr class="my-4">
              <button class="flex ml-4" @click="addForm">
                <Icon size="s" icon-name="icon-plus" />
                <p class="ml-3 text-flame font-semibold">Add</p>
              </button>
            </tr>
            <tr class="h-7" />
          </tbody>
        </table>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "FilterPayrun",
  components: {
    ValidationObserver,
    RightSideBar: () => import("@/components/RightSideBar"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "addition",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      forms: [],
      Options: [
        { name: "Addition", id: "addition" },
        { name: "Deduction", id: "deduction" },
      ],
      disableButton: false,
      loader: false,
      payItems: [],
      totalAmount: 0,
      headers: [
        { title: "Item" },
        { title: "Amount Due (To Date)" },
        { title: "Type" },
      ],
    };
  },
  watch: {
    openModal() {
      if (this.openModal) {
        if (!this.forms) {
          this.addForm();
        }
        this.loader = true;
        this.$_getAllPayItems(this.userId).then((response) => {
          const outstandingAdditions = response.data.additions;
          const outstandingDeductions = response.data.deductions;

          outstandingAdditions.forEach((addition) => {
            this.payItems.push({
              id: addition.id,
              name: addition.payItemName,
              amount: addition.amount,
              type: "addition",
            });
          });

          outstandingDeductions.forEach((deduction) => {
            this.payItems.push({
              id: deduction.id,
              name: deduction.payItemName,
              amount: deduction.amount,
              type: "deduction",
            });
          });

          this.forms =
            this.payItems.length === 0
              ? [
                  {
                    name: "",
                    amount: "",
                    type: "",
                  },
                ]
              : this.payItems;

          this.setNetOutstanding();
          this.loader = false;
        });
      }
    },
  },
  methods: {
    setNetOutstanding() {
      let additions = 0;
      let deductions = 0;
      this.forms.forEach((item) => {
        if (item.type === "addition") {
          additions += Number(item.amount);
        } else {
          deductions += Number(item.amount);
        }
      });

      this.totalAmount = additions - deductions;
    },

    handleCategoryChange(event, index) {
      if (event === "reimbursement") {
        this.forms[index].isTaxable = "false";
        this.forms[index].disable = true;
      }
    },
    addForm() {
      this.forms.push({
        name: "",
        amount: "",
        type: "",
      });
    },
    removeForm(terminalItem, index) {
      if (!terminalItem.id) this.forms.splice(index, 1)
      else this.$_removeTerminalPay(terminalItem.id).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 5000 })
        this.forms.splice(index, 1)
      }).catch((error) => {
        this.$toasted.error(error, { duration: 5000 })
      })
    },
    closeModal() {
      this.forms = [];
      this.payItems = [];
      this.$emit("close");
    },
    submit() {
      this.disableButton = true;
      const outstandings = [];
      this.forms.forEach((item) => {
        outstandings.push({
          amount: item.amount,
          name: item.name,
          type: item.type,
          id: item.id
        });
      });

      const payload = [{ employeeIds: this.userId, outstandings }];

      this.$_processOutstandings(payload)
        .then(() => {
          this.forms = [];
          this.payItems = [];
          this.$emit("close");
          this.disableButton = false;
          this.$toasted.success(`Outstandings added successfully`, {
            duration: 3000,
          });
        })
        .catch((error) => {
          this.disableButton = false;
          this.$toasted.error(`Outstandings not added successfully`, {
            duration: 5000,
          });
          throw new Error(error);
        });
    },
  },
  mounted() {
    this.addForm();
  },
};
</script>

<style>
.input label {
  /* display: none; */
}
tr:nth-child(even) {
  background-color: white;
}
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
